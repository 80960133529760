import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import Jumbotron from "../components/jumbotron";
import Panzerotto from "../templates/panzerotto";
import panzerottoAndDessert from "../images/background/panzerotto-and-dessert.jpg";
import panzerottoOpen from "../images/background/open-panzerotto.jpg";
import panzerottoKnead from "../images/background/panzerotto-cooking.jpg";
import panzerottoOurStory from "../images/background/panzerotto-our-story.jpg";
import Button from "../components/button";
import { Trans } from "react-i18next";
import AllReviews from "../components/all_reviews";
import { headingClass } from "../components/heading";
import { PromoWebshop } from "../components/promo";
import SEO from "../seo";
import { InternalHeaderSpacer } from "../components/header";

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="p-4 max-w-lg bg-white/50 backdrop-blur-sm">{children}</div>
);

const Filling = ({ children }: { children: React.ReactNode }) => (
  <div className="gap-4 flex flex-col lg:mx-auto max-w-screen-lg">
    {children}
  </div>
);

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <p className="text-md lg:text-lg mt-4">{children}</p>
);

//css={css`text-shadow: 0 2px 2px rgba(250, 100, 0, 0.4), 0 4px 4px rgba(250, 200, 20, 0.4);`}
const Title = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <h3 className={`${headingClass} ${className}`}>
    <span className="bg-white/50">{children}</span>
  </h3>
);

const IndexPage = () => {
  return (
    <Layout className="snap-mandatory">
      <Jumbotron />
      <Panzerotto
        id="apulian-street-food"
        background={`center right / cover url(${panzerottoAndDessert})`}
      >
        <InternalHeaderSpacer />
        <Filling>
          <Container>
            <Title>
              <Trans>Apulian Style Streat Food{"\n"} from Southern Italy</Trans>
            </Title>
            <Paragraph>
              {/* prettier-ignore */}
              <Trans>We are passionate about Apulian-style cuisine and can't wait to share all the deliciousness with you! We source only the highest quality ingredients to create tasty dishes inspired by our Pugliese heritage. By using time-honoured techniques passed down through generations, we ensure that every dish is packed with the mouthwatering flavours and tempting aromas of Puglia. Panzerò is far from your typical pizzeria. You won't find any of the “normal” Italian cuisines like traditional pizza, pasta, or calzones here. Our Apulian roots mean we offer a distinctly different Italian food experience you're sure to love and remember!</Trans>
            </Paragraph>
          </Container>
          <Button to="/menu" className="w-fit self-center lg:self-start">
            <Trans>See Our Menu</Trans>
          </Button>
        </Filling>
      </Panzerotto>

      <Panzerotto background={`center center / cover url(${panzerottoOpen})`}>
        <InternalHeaderSpacer />
        <Filling>
          <Container>
            <Title>
              <Trans>Our Panzerotti</Trans>
            </Title>
            <Paragraph>
              {/* prettier-ignore */}
              <Trans>Panzerotti are considered Puglia's most beloved street food and one of our specialties! While our panzerotti might resemble a calzone or folded pizza at first glance, one bite and you’ll soon understand why this Puglia pizza is unlike anything you’ve tried before. The difference is in the dough! These pockets of fried dough perfection come filled with a plethora of various savory and sweet fillings. Ours are handcrafted with the finest Italian ingredients imported directly from Puglia right to your table!</Trans>
            </Paragraph>
          </Container>
          <Button to="/ingredients" className="w-fit self-center lg:self-start">
            <Trans>Explore Our Ingredients</Trans>
          </Button>
        </Filling>
      </Panzerotto>

      <Panzerotto background={`center center / cover url(${panzerottoKnead})`}>
        <InternalHeaderSpacer />
        <Filling>
          <Container>
            <Title>
              <Trans>Our Panzerotti Promise</Trans>
            </Title>
            <Paragraph>
              {/* prettier-ignore */}
              <Trans>We are an innovative and authentic fast-casual Italian restaurant that is eager to share our Italian traditions. We are extremely happy to be part of Rotterdam’s amazing culinary landscape and we aim to be a place where you can come with your friends or family and know that you will be well looked after. We promise to provide you with excellent food, wine, coffee, and a dining experience that exceeds your expectations! Whether you’re dining in, using our delivery service, or popping in yourself to grab your takeaway order, your food will always be fresh and delicious!</Trans>
            </Paragraph>
          </Container>
        </Filling>
      </Panzerotto>
      <Panzerotto
        id="reviews"
        background={`center center / cover url(${panzerottoOurStory})`}
      >
        <InternalHeaderSpacer />
        <div className="flex flex-col items-center flex-auto">
          <Title className="p-4">
            <Trans>What our costumers love about Panzerò</Trans>
          </Title>
          <AllReviews />
        </div>
      </Panzerotto>
      <PromoWebshop />
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
