import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
const Review = ({
  title,
  author,
  review,
  website,
  score = "/",
}: {
  title: string;
  author: string;
  review: string;
  website: string;
  score: string;
}) => (
  <li className="relative snap-center flex-shrink-0 rounded-lg m-4 first:ml-[25%] lg:first:ml-4 last:mr-[25%] lg:last:mr-4 shadow-xl w-[80vw] max-w-lg">
    <div className="bg-gradient-to-t from-white/70 to-white/100 rounded-t-lg p-2 min-h-[4rem]">
      <h5 className="text-xl text-red-500 mr-20">
        <Trans>{title}</Trans>
      </h5>
      <div className="inline bg-yellow-500/50">{author}</div>
      <div className="text-sm opacity-50">via {website}</div>
    </div>
    <div className="bg-white/80 rounded-b-lg p-2">
      <div className="text-green-600/90 absolute top-2 right-2 px-2">
        <span className="text-6xl">{score.split("/")[0]}</span>
        <span className="text-5xl opacity-50">/{score.split("/")[1]}</span>
      </div>
      <div className="whitespace-pre-line">
        <Trans>{review}</Trans>
      </div>
    </div>
  </li>
);

const AllReviews = ({ className = "" }) => {
  const { language } = useI18next();
  const {
    allReviewsJson: { nodes: allReviews },
  } = useStaticQuery(graphql`
    query ReviewsQuery {
      allReviewsJson {
        nodes {
          en {
            author
            review
            score
            title
            website
          }
          nl {
            review
            author
            score
            title
            website
          }
        }
      }
    }
  `);
  return (
    <div className="relative lg:static w-full py-4">
      <ul className="absolute lg:static w-full overflow-x-auto lg:overflow-x-hidden flex lg:flex-wrap lg:justify-center snap-mandatory snap-x pb-8 items-start">
        {allReviews[0][language].map((review: any) => (
          <Review {...review} key={review.author} />
        ))}
      </ul>
    </div>
  );
};

export default AllReviews;
