import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import background from "../images/background/panzerotti.jpg";
import panzero from "../images/logo/panzero.png";
import { HeaderSpacer } from "./header";
import { Trans } from "react-i18next";

const Jumbotron = () => (
  <div
    style={{
      background: `center / cover url(${background}) no-repeat`,
    }}
    className="snap-start relative min-h-screen h-fit max-w-screen flex flex-col justify-items-center"
  >
    <HeaderSpacer className="text-white bg-current opacity-50" />
    <div className="text-white px-4 flex-auto mt-32">
      <h2 className="inline leading-none text-4xl font-black whitespace-pre">
        <img
          src={panzero}
          alt="Panzerò"
          className="drop-shadow-lg  w-4/5 max-w-lg"
        />
      </h2>

      <h3
        css={css`
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
        `}
        className="whitespace-pre-wrap font-semibold text-4xl mt-4"
      >
        <q
          className="leading-relaxed tracking-wide"
          lang="en"
          style={{ quotes: `"\\201C" "\\201D"` }}
        >
          {/* prettier-ignore */}
          <Trans>Italy at first bite!</Trans>
        </q>
      </h3>
    </div>
    <p className="flex flex-col justify-center text-center p-4 mb-8 self-center text-white">
      <a
        className="text-red-500 uppercase text-xl bg-white/80 hover:scale-105 transition-transform"
        href="#apulian-street-food"
      >
        <Trans>Learn More</Trans>
      </a>
      <span className="animate-bounce w-32 mt-4 flex justify-center drop-shadow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </span>
    </p>
  </div>
);

export default Jumbotron;
